.faq {
	margin-top: 20px;
	margin-bottom: 120px;

	font-family: Poppins;
	font-style: normal;

	&__title {
		margin-bottom: 70px;

		font-weight: bold;
		font-size: 35px;
		line-height: 114%;
		text-transform: uppercase;
		color: #242d3a;

		text-align: center;
	}

	&__wrapper {
		max-width: 1021px;
		margin: 0 auto;

		padding: 0 20px;
		border-bottom: 1px solid #e7e8ed;
	}

	&__item {
		&-top {
			border-top: 1px solid #e7e8ed;
			cursor: pointer;

			padding: 20px;
			min-height: 80px;

			display: grid;
			grid-template-columns: 1fr 40px;
			grid-column-gap: 15px;
			align-items: center;

			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			color: #242d3a;
		}
		&-icon {
			width: 40px;
			height: 40px;

			display: flex;
			align-items: center;
			justify-content: center;

			background: #2e7cf6;
			border-radius: 50%;
		}
		&-plus {
			width: 21px;
			height: 21px;

			transition: transform 0.3s ease-in-out;

			&--active {
				transform: rotate(-45deg);
			}
		}
		&-bottom {
			overflow: hidden;
			&-text {
				padding: 10px 75px 30px 20px;
				font-weight: normal;
				font-size: 16px;
				line-height: 156%;
				color: #464646;
				a {
					color: #2e7cf6;
				}
			}
			&--opened {
				opacity: 1;
				max-height: 1400px;
				transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
				height: auto;
			}
			&--closed {
				transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out,
					height 0.5s ease-in-out, padding 0.5s ease-in-out;
				opacity: 0;
				max-height: 0;
				height: 0;
			}
		}
	}
}

@media only screen and (max-width: 480px) {
	.faq {
		margin-top: 0;
		margin-bottom: 50px;

		&__title {
			margin-bottom: 50px;
		}

		&__item {
			&-bottom {
				&-text {
					padding: 5px 75px 15px 20px;
				}
			}
		}
	}
}
