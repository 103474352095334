.products {
	margin-top: 50px;

	font-family: Poppins;
	font-style: normal;

	&__wrapper {
		max-width: 1021px;
		margin: 0 auto;
	}

	&__product {
		.product {
			&__wrapper {
				margin: 0 20px;
				border-bottom: 1px solid #e7e8ed;
			}
			&__info {
				display: grid;
				grid-template-columns: 121px 1fr;
				grid-column-gap: 28px;

				align-items: center;
			}
			&__icon {
				width: 121px;
			}
			&__title {
				margin-bottom: 5px;

				font-weight: bold;
				font-size: 19px;
				line-height: 126%;
				color: #242d3a;
			}
			&__rating {
				display: flex;
				align-items: center;

				font-weight: bold;
				font-size: 20px;
				line-height: 150%;
				color: #242d3a;
				&-icon {
					width: 100px;
					margin-left: 7px;
				}
			}
			&__comments {
				font-weight: normal;
				font-size: 13px;
				line-height: 138%;
				color: #242d3a;
				opacity: 0.65;
			}

			&__platforms {
				margin: 15px 0;

				background: #f2f7fc;
				border-radius: 8px;

				padding: 10px 20px;

				text-align: center;
				font-weight: normal;
				font-size: 14px;
				line-height: 179%;
				color: #242d3a;
			}

			&__slider {
				padding-top: 15px;
				margin-bottom: 125px;
			}
		}
	}
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-slide {
	max-width: 234px;
}

.swiper-slide:nth-child(1) {
	margin-left: 20px;
}

.swiper-slide:nth-child(4) {
	margin-right: 20px;
}

@media only screen and (max-width: 480px) {
	.products {
		margin-top: 30px;

		&__product {
			.product {
				&__wrapper {
					margin: 0 20px;
				}

				&__platforms {
					margin: 15px 0;
					border-radius: 4px;
					padding: 5px 20px;
				}

				&__slider {
					padding-top: 15px;
					margin-bottom: 95px;
				}
			}
		}
	}

	.swiper-slide {
		max-width: 180px;
	}
}

.swiper-wrapper {
	padding-bottom: 25px;
}
.swiper-pagination {
	bottom: 10px !important;
	height: 5px;
	border-radius: 3px;
	z-index: 1000;
}

.swiper-pagination-bullet {
	background: transparent;
	width: 20%;
}
.swiper-pagination-bullet-active {
	background: #e7e8ed;
	border-radius: 3px;
}
