// prettier-ignore
*{
	margin: 0;
	padding: 0;
	border: 0;
    line-height: 1;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

textarea,
input[type='range'],
input,
input:matches([type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url']) {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

body {
	font-family: 'Poppins', sans-serif;
	background: #ffffff;
}
