.footer {
	&__wrapper {
		margin-bottom: 25px;
		padding: 0 25px;
		font-family: Poppins;

		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 138%;
		text-align: center;
		color: #9199a4;
	}
}
