.navbar {
	background: #ffffff;
	box-shadow: 0px 1px 0px #dee4ee;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	height: 68px;

	display: flex;
	align-items: center;

	&__wrapper {
		max-width: 1440px;
		width: 100%;
		padding: 0 50px;
		margin: 0 auto;

		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	&__btn-home {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 216px;
		height: 50px;
		padding: 5px 10px;

		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 130%;
		color: #ffffff;

		background: #2e7cf6;
		border-radius: 30px;

		cursor: pointer;
	}
	&__message-ico {
		width: 24px;
		margin-right: 11px;
	}
}

@media only screen and (max-width: 480px) {
	.navbar {
		&__wrapper {
			padding: 0 20px;
		}
		&__btn-home {
			width: 216px;
			height: 50px;
			padding: 5px 10px;

			border-radius: 15px;
		}
	}
}
